.main_container{
    display:flex;
    flex-direction: column;
    text-align: center;
    gap:10px;
    align-items: center;

    .title{
        font-size: 24px;
        font-family: var(--font_family_Bold) !important;
    }

    .description{
        font-size: 18px;
        font-family: var(--font_family_regular) !important;
        margin-bottom: 20px;
    }
    .btn{
        font-size: 16px;
        font-family: var(--font_family_SemiBold) !important;;
    }
}