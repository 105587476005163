.switchTabContainer {
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
    padding: 5px;
    margin: 30px 0;
    border-radius: 4px;
    display: flex;
    gap: 10px;
    .switchTab {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-weight: 800;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
    }
    .tabActive {
        background: rgba(0,124,131,0.2);
        color: var(--primary_brand_color_dark);
    }
    .tabInactive {
        color: var(--tab_color_2);
        opacity: 0.2;
    }
}
