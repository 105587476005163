.plan_card {
  min-width: 300px;
  width: 300px;
  margin: 0 10px 20px 10px;
  padding: 25px 0;
  border-radius: 4px;
  box-shadow: 0 0 1px 1px rgba(83, 83, 83, 0.5), 0 0 0 0 var(--black);
  border: 1px solid var(--card-color);
  background-color: var(--white);
  position: relative;
}

.planData {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;

  .planImg {
    width: 20px;
    height: 18px;
  }

  .planRate {
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: var(--text_color);
    margin: 0 5px;
  }

  .planText {
    font-size: 15px;
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: var(--text_color);
  }
}

.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 20px auto;
}