.byod {
  .content_section {
    .byod_line {
      width: 80px;
      height: 5px;
      border-radius: 2.5px;
      background-color: var(--divider_color);
    }

    .btn_section {
      .btn_cmp1,
      .btn_cmp2 {
        width: 100%;
        max-width: 200px;
      }
    }
  }
  .byod_home_img {
    @media (max-width: 899px) {
      width: 100%;
    }
    @media (min-width: 900px) {
      width: 95%;
    }
    object-fit: contain;
    height: 100%;
  }
}

@mixin minHeight($mHeight, $iHeight, $dHeight) {
  min-height: $dHeight;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    min-height: $iHeight;
  }

  @media screen and (max-width: 600px) {
    min-height: $mHeight;
  }
}

:global(.min_height_byod_section_reach) {
  @include minHeight(607px, 705px, 455px);
}

:global(.min_height_byod_section_ghost) {
  @include minHeight(591px, 743px, 425px);
}
