.step1_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .heading_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0px 15px 0px;
  
      .arrow_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        z-index: 100;
        left: 0;
        position: absolute;
      }
  
      .title_main {
        font-family: var(--font_family_Bold);
        font-size: 22px;
        font-weight: var(--font_weight_4);
        line-height: 1;
        text-align: center;
        color: var(--text_color);
      }
    }
  
    .stepper_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .stepper_txt {
        font-family: var(--font_family_Medium);
        color: var(--text_color);
        font-size: 16px;
        padding-bottom: 20px;
      }
    }
  
    .step1_container {
      border-radius: 6px;
      box-shadow: 0 0 1px 1px rgb(60 64 67 / 30%);
      border: 1px solid var(--chinese-silver);
      background-color: var(--lightgrey_6);
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .step1_heading {
        font-family: var(--font_family_Semibold);
        font-size: var(--description_secondary_text_fs_desktop);
        text-align: center;
        color: var(--text_color_2);
        margin-bottom: 20px;
      }
  
      .device_text_container {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        @media screen and (max-width: 385px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
  
        @media screen and (min-width: 386px) and (max-width: 600px) {
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
        }
  
        @media screen and (min-width: 601px) and (max-width: 763px) {
          flex-direction: column;
          align-items: flex-start;
        }
  
        @media screen and (min-width: 763px) and (max-width: 956px) {
          justify-content: space-between;
          align-items: flex-start;
        }
  
        @media screen and (min-width: 957px) {
          width: 65%;
          justify-content: space-between;
          align-items: flex-start;
        }
  
        .device_container {
          display: flex;
          align-items: flex-start;
          margin: 5px;
  
          .device_icon {
            vertical-align: middle;
            color: rgb(33, 37, 41);
            width: 19px;
            height: 21px;
            margin-right: 15px;
            margin-top: 3px;
            font-size: 16px;
          }
  
          .text_container {
            display: flex;
            flex-direction: column;
  
            .text1 {
              font-family: var(--font_family_Semibold);
              font-size: 14px;
              color: var(--text_color_2);
              font-weight: 400;
              line-height: 21px;
            }
  
            .text2 {
              font-family: var(--font_family_Medium);
              font-size: 14px;
              color: var(--text_color);
              font-weight: 400;
              line-height: 21px;
            }
          }
        }
      }
  
      .edit_text {
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        margin-top: 25px;
        color: rgb(72, 104, 143);
        font-family: var(--font_family_Bold);
        font-weight: 400;
      }
  
      .edit_text {
        color: var(--primary_color);
        cursor: pointer;
        margin-left: 2px;
        font-size: 13px;
        font-weight: 600;
        font-weight: var(--font_weight_2);
        text-align: center;
        text-decoration: underline;
      }
    }
  
    .inner_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 6px;
      box-shadow: 0 0 1px 1px rgb(60 64 67 / 30%);
      border: 1px solid var(--chinese-silver);
      background-color: var(--lightgrey_6);
  
      .activation_heading {
        display: flex;
        justify-content: center;
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        text-align: center;
        color: var(--text_color_2);
        padding-bottom: 0;
        margin-bottom: 20px;
      }
  
      .activation_iemi_number {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
  
        .activation_limit_div {
          align-self: flex-end;
          margin: 10px 0;
          font-family: var(--font_family_Medium);
          font-weight: var(--font_weight_0);
          font-size: 12px;
        }
      }
  
      .activation_paid_desc {
        margin-top: 25px;
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-style: italic;
        color: var(--text_color);
        text-align: center;
        margin-bottom: 0;
      }
  
      .activation_btn {
        display: flex;
        justify-content: center;
      }
    }
  
    .activation_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      .activation_bottom_text1 {
        display: flex;
        justify-content: center;
        font-size: 14px;
        padding-bottom: 0;
        color: var(--text_color_2);
      }
  
      .activation_version_container {
        display: flex;
  
        .os_img {
          margin-right: 1rem;
        }
  
        .os_text_1 {
          font-family: var(--font_family_Semibold);
          font-size: 14px;
          color: var(--text_color_2);
        }
  
        .os_text_2 {
          font-family: var(--font_family_Medium);
          font-size: 14px;
          color: var(--text_color);
        }
      }
    }
  }