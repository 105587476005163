.currentPlan {
  color: var(--primary_brand_color);
  margin: 5px 0;
  font-family: var(--font_family_Semibold);
}
.active_plan {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.in_active_plan {
  cursor: pointer;
}
.active_item_label {
  color: var(--primary_brand_color);
  font-family: var(--font_family_Semibold);
  margin: 0 0 5px 0;
}
.move_next_month_label {
  color: var(--date_picker_inp);
  font-size: 14px;
  font-family: var(--font_family_Semibold);
  margin-top: 10px;
}

.ActiveCard {
  background: var(--primary_brand_color) !important;
  color: #fff !important;
}

.CheckoutCardLoader {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 158px;
}
 
.CheckoutCard {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;

  .leftDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 40%;

    .cardTitle {
      font-size: 18px;
      font-family: var(--font_family_Semibold);
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .cardSubtext {
      font-size: 20px;
    }
  }

  .rightDiv {
    width: 60%;

    .gbData {
      font-family: var(--font_family_Semibold);
      font-size: 20px;
      text-align: right;

      .monthData {
        font-family: var(--font_family_Medium);
        color: var(--grey_shade);
        font-size: 14px;
      }

      .monthDataActive {
        font-family: var(--font_family_Medium);
        color: #fff;
        font-size: 14px;
      }
    }

    .sharable {
      font-size: 14px;
      font-family: var(--font_family_Medium);
      text-align: right;
    }

    .addLine {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      text-align: right;

      .perMonth {
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
}

.selectPlanAndProceedBtn {
  width: 60%;
  margin: 20px auto;
}

.active_plan#actvie_card_border > .CheckoutCard {
  border: 2px solid var(--primary_brand_color);
}
.cursor_block {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.top_container {
  padding: 21px 5px 25px 5px !important;
  background-color: var(--plan_card_upper_bg_color); //var(--primary_color) !important;
  border-radius: 40px 40px 0px 0px;
}
.bottom_container {
  padding: 21px 23px 25px 25px !important;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  border-radius: 40px 4px 4px 0;
  margin-left: 3px;
  font-size: 12px;
  font-weight: var(--font_weight_2);
}

.title {
  font-family: var(--font_family_Medium);
  font-size: 22px;
  font-weight: var(--font_weight_4) !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: var(--text_color);
}

.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 12px auto;
  background-color: var(--divider_color) !important;
}

.save_money_area {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: var(--font_family_Semibold);
  font-size: 12px;
  color: var(--text_color);

  .save_money_img {
    height: 15px;
    padding: 0 4px 4px 0;
  }
}

.plan_cost_data {
  text-align: center;

  .cost {
    margin: 0px;
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--light_black_1);
  }

  .cost1 {
    margin: 11px 0 7px 0px;
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--light_black_2);
    font-weight: var(--font_weight_4);
  }

  &:hover {
    box-shadow: none;
  }
}

.plan_page_offer {
  margin-left: 10px;
  font-family: var(--font_family_Medium);
  font-size: 16px;
  font-weight: var(--font_weight_1);
  color: var(--text_color);
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
}
.broad_clicked{
  font-family: var(--font_family_Bold);
  margin-right: 10px;
  color: #fff !important;
}