@mixin font($size, $fontFaimly, $fontWeight) {
  font-size: $size;
  font-family: $fontFaimly;
  font-weight: $fontWeight;
}

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  @include font(16px, var(--font_family_Semibold), var(--font_weight_0));

  .title {
    @include font(24px, var(--font_family_Bold), var(--font_weight_4));
    color: var(--primary_color);
  }

  .desc {
    @include font(16px, var(--font_family_Semibold), var(--font_weight_4));
  }
}
