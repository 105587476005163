.update_card_dialog{
    text-align: center;
    background-color: var(--white);
    border: 1px solid rgba(0,0,0,.2) !important;
    width: 500px !important;
    .update_card_heading{
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      color: var(--text_color);
      margin-bottom: 25px;
      padding-top: 15px;
      line-height: 1;
    }
    .text_field{
        font-family: var(--font_family_Medium);
        padding-bottom: 20px;        
        border-radius: 6px;
        color: var(--text_color);
        :global(.MuiFilledInput-input) {
            height: 19px;}
    }
    .text_field1{
        font-family: var(--font_family_Medium);
        padding-bottom: 20px;
        border-radius: 6px;
        color: var(--text_color);
        :global(.MuiFilledInput-input) {
            opacity: 1 !important;
            background-color: var(--lightgrey_4) !important;
            -webkit-text-fill-color: var(--text_color) !important;
            height: 19px;
        }
    }
  }