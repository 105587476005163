.SearchBox {
  width: 100%;
  box-shadow: none !important;
  border-radius: 6px !important;
  border: solid 1px var(--grey77-color) !important;
  background-color: var(--white) !important;
  .MuiInputBase-root {
    color: var(--text_color);
  }
  .MuiInputBase-input {
    font-family: var(--font_family_Bold);
  }
}
.SearchBoxDropdown {
  .SearchBoxTextField {
    width: 100%;
    .MuiFilledInput-input {
      padding: 7px 0px;
      background: var(--white);
      border-radius: 6px;
      color: var(--text_color);
      font-family: var(--font_family_Semibold);
    }
    .MuiInputLabel-filled {
      z-index: 1;
      transform: translate(15px, 21px) scale(1);
      color: var(--grey_shade);
      font-family: var(--font_family_Semibold);
    }
    .MuiFilledInput-root:hover {
      border-color: var(--primary_brand_color);
    }
    .MuiFilledInput-root {
      border-radius: 6px;
      border: solid 1px var(--radio-color);
      background-color: var(--white);
    }
    .MuiFilledInput-root.Mui-error {
      border-color: var(--danger);
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
      z-index: -1;
    }
    .MuiFilledInput-underline:hover:before,
    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline:after {
      border: none;
    }
    .MuiFilledInput-underline.Mui-disabled:before {
      border: none;
    }
    .MuiInputBase-root.MuiFilledInput-root {
      padding: 5px 10px;
    }
  }
}
.MuiAutocomplete-popper li {
  color: var(--text_color);
  font-family: var(--font_family_Semibold) !important;
  font-size: 14px !important;
  line-height: 40px;
  border-bottom: 1px solid var(--ford-grey-color);
  background-color: var(--white);
  margin: 0px;
  padding: 15px;
  text-decoration: none;
  display: block;
}
.MuiAutocomplete-popper li:hover {
  background-color: var(--primary_color) !important;
  color: var(--white);
  cursor: pointer;
}
.MuiAutocomplete-popper {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 #00000080;
  border: 1px solid var(--ford-grey-color);

  @media (max-width: 600px) {
    width: 90% !important;
  }
}
