.privacy_main_container {
    margin: 0px auto 0px auto;
    padding: 50px 0;
    width: 80%;
    .privacy_content {
      color: var(--text_color);
      p {
        margin: 20px 0px 0px 0px;
      }
      ul {
        padding-left: 32px;
      }
      .privacy_title {
        font-size: 28px !important;
        line-height: 1.43;
        text-align: center;
        font-family: var(--font_family_Semibold);
        font-weight: var(--font_weight_2);
        margin-top: 0;
      }
      .privacy_line {
        width: 130px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
        margin: 10px auto 20px auto;
      }
      .privacy_text {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-weight: normal;
        line-height: 2.14;
        letter-spacing: normal;
        margin: 20px 0px 0px 0px;
        h1 {
          font-size: 16px !important;
          text-transform: uppercase;
          font-family: var(--font_family_Semibold) !important;
          font-weight: var(--font_weight_2) !important;
          line-height: 1;
          margin: 30px 0 0;
        }
      }
      .privacy_top_para {
        margin-top: 50px;
      }
      .privacy_heading {
        font-size: 16px !important;
        text-transform: uppercase;
        font-family: var(--font_family_Semibold) !important;
        font-weight: var(--font_weight_2) !important;
        line-height: 1;
        margin-top: 30px;
      }
      .privacy_subheading {
        font-size: 16px !important;
        line-height: 1.88;
        letter-spacing: normal;
        margin-top: 18px;
      }
    }
  
    @media (max-width: 600px) {
      width: calc(100% - 40px);
    }
    @media (max-width: 1024px) {
      margin-top: 71px;
    }
  }
  