.cards_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
}
.home_page_title {
  color: var(--text_color);
  text-align: center;
}
.home_page_subtitle {
  margin-bottom: 10px;
  text-align: center;
}
.icon_text_container {
  .check_icon {
    fill: var(--primary_brand_color);
    margin-right: 3px;
    width: 1em;
    height: 1em;
  }
  .offer_text {
    color: var(--text_color);
    font-family: var(--font_family_Bold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    line-height: 1;
  }
}
@mixin minHeight($mHeight, $iHeight, $dHeight) {
  min-height: $dHeight;
  @media screen and (min-width: 600px) and (max-width: 900px) {
    min-height: $iHeight;
  }
  @media screen and (max-width: 600px) {
    min-height: $mHeight;
  }
}

:global(.min_height_plan_section_reach) {
  @include minHeight(1092px, 1152px, 706px)
}


:global(.min_height_plan_section_wow) {
  @include minHeight(2100px, 1195px, 765px)
}


:global(.min_height_plan_section_ghost) {
  @include minHeight(1150px, 705px, 305px)
}