.login_singup_main_1 {
  width: min-content;
  max-width: min-content;
  min-width: 38%;
}
.login_singup_main_2 {
  width: 550px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (min-width: 768px) and (max-width: 900px) {
    width: 60%;
  }
  @media screen and (min-width: 900px) and (max-width: 1024px) {
    width: 55%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    width: 50%;
  }
}