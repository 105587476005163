:root {
  --font_weight_0: 400;
  --font_weight_1: 500;
  --font_weight_2: 600;
  --font_weight_3: 900;
  --font_weight_4: 700;
  --font_weight_5: 800;
}

html,
body,
p,
a,
span,
h1,
div,
section,
ul,
li,
button,
h2,
h3,
h4,
h5,
strong,
input,
option,
h6 {
  font-family: var(--font_family_Medium);
}

html::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
html,
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Use h3 tag for all section header */
h3 {
  font-family: var(--h3_font_family) !important;
  font-size: var(--h3_font_size_desktop) !important;
  font-weight: var(--h3_font_weight) !important;
  @media (max-width: 600px) {
    font-size: var(--h3_font_size_mobile) !important;
  }
}
/* Use h4 tag for all section sub header */
h4 {
  font-family: var(--h4_font_family) !important;
  font-size: var(--h4_font_size_desktop) !important;
  font-weight: var(--h4_font_weight) !important;
  @media (max-width: 600px) {
    font-size: var(--h4_font_size_mobile) !important;
  }
}
/*For description content */
.description_text {
  font-family: var(--description_text_font_family) !important;
  font-size: var(--description_text_font_size_desktop) !important;
  font-weight: var(--description_text_font_weight) !important;
}
.description_secondary_txt {
  font-family: var(--description_text_font_family) !important;
  font-size: var(--description_secondary_text_fs_desktop) !important;
  font-weight: var(--description_text_font_weight) !important;
}
/*For page header across all pages */
.page_header {
  font-family: var(--page_header_font_family) !important;
  font-size: var(--page_header_font_size_desktop) !important;
  font-weight: var(--page_header_font_weight) !important;
}
.main_banner_title {
  font-family: var(--main_banner_title_ff) !important;
  font-size: var(--main_banner_title_fs_desktop) !important;
  font-weight: var(--main_banner_title_fw) !important;
  @media (max-width: 600px) {
    font-size: var(--main_banner_title_fs_mobile) !important;
  }
  @media (767px < width < 1301px) {
    font-size: var(--main_banner_title_fs_ipad) !important;
  }
}
.main_banner_sub_title {
  font-family: var(--main_banner_sub_title_ff) !important;
  font-size: var(--main_banner_sub_title_fs_desktop) !important;
  font-weight: var(--main_banner_sub_title_fw) !important;
  @media (max-width: 600px) {
    font-size: var(--main_banner_sub_title_fs_mobile) !important;
  }
  @media (767px < width < 1301px) {
    font-size: var(--main_banner_sub_title_fs_ipad) !important;
  }
}
.internal_banner_title {
  font-family: var(--internal_banner_title_ff) !important;
  font-size: var(--internal_banner_title_fs_desktop) !important;
  font-weight: var(--internal_banner_title_fw) !important;
  @media (max-width: 600px) {
    font-size: var(--internal_banner_title_fs_mobile) !important;
  }
  @media (767px < width < 1301px) {
    font-size: var(--internal_banner_title_fs_ipad) !important;
  }
}
