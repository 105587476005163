.terms_page_content {
	padding: 50px 0;
	margin: 0px auto 0px auto;
	display: grid;
	justify-content: space-evenly;
	width: 80%;
	// @media screen and (max-width: 500px) {
	// 	.content {
	// 		margin-left: 20px;
	// 		margin-right: 20px;
	// 	}
	// }
	// @media screen and (min-width: 1600px) {
	// 	.content {
	// 		margin-left: 190px;
	// 		margin-right: 190px;
	// 	}
	// }
	.terms_text {
		font-family: var(--font_family_Medium);
		font-size: 14px;
		font-weight: normal;
		line-height: 2.14;
		letter-spacing: normal;
		margin: 20px 0px 0px 0px;
		h1 {
			font-size: 16px !important;
			text-transform: uppercase;
			font-family: var(--font_family_Semibold) !important;
			font-weight: var(--font_weight_2) !important;
			line-height: 1;
			margin: 30px 0 0;
		}
	}
	.terms_top_para {
		margin-top: 50px;
	}
	.terms_title {
		font-family: var(--font_family_Semibold);
		font-size: 28px;
		font-weight: var(--font_weight_2);
		line-height: 1.43;
		letter-spacing: normal;
		color: var(--text_color);
		text-align: center;
	}
	.terms_headings {
		font-family: var(--font_family_Semibold);
		font-size: 18px;
		font-weight: var(--font_weight_4);
		color: var(--text_color);
		text-align: left;
		line-height: normal;
	}
	.terms_para {
		font-size: 15px;
		font-weight: var(--font_weight_0);
		line-height: 2.27;
		justify-content: space-evenly;
		color: var(--text_color_3);
		font-family: var(--font_family_Medium);
		margin: 20px 0;
		padding-left: 30px;
		text-align: left;
		font-size: 15px;
	}
	a {
		text-decoration: none;
		color: var(--terms_anchor_tag_color);
		font-weight: var(--terms_anchor_tag_font_weight);
	}
	.terms_line {
		width: 80px;
		height: 5px;
		border-radius: 2.5px;
		background-color: var(--primary_brand_color);
		margin: 10px auto 50px auto;
	}
}

