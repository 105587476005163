.line_count_button {
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: var(--white);
  font-size: 14px;
  width: 1.875em;
  height: 1.875em;
  color: var(--primary_color);
  font-family: var(--font_family) !important;
  font-weight: var(--font_weight_0);
  line-height: 1.5;
  cursor: pointer;
}
.toggle_item{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: var(--font_weight_4);
  text-align: right;
  color: var(--primary_color);
  cursor: pointer;
}
.sub_title{
  text-align: center;
  margin-bottom: 20px; 
  font-weight: var(--font_weight_0);
  color: var(--grey_shade);
  font-family: var(--font_family_Semibold)
}
.review_popper{
  :global(.MuiPopper-arrow::before) {
      border-color: transparent transparent var(--black) transparent !important;
  }
}