.tab_component {
  border: solid 1px var(--line_tab_border_color);
  background-color: var(--white);
  border-radius: var(--line_tab_border_radius) !important;
  box-shadow: var(--line_tab_box_shadow);
  @media (max-width: 600px) {
    padding: 7px 10px !important;
  }
  .tab_div {
    margin: 0 17.5px;
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 600px) {
      margin: 0 12.5px;
    }
    @media (max-width: 320px) {
      margin: 0 6px;
    }
  }
  .tab-detail {
    color: var(--text_color);
  }
  .tab_detail_active {
    width: 60px;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--primary_brand_color);
    text-align: center;
  }
}
