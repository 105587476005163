.header_modal {
  z-index: 1111 !important;
  .header_container {
    width: 100%;
  }
 
  :global(.MuiBackdrop-root) {
    background-color: var(--white) !important;
  }

  .dialog_container {
    padding-bottom: 20px;
    .active_link_item {
      border-bottom: 4px solid var(--primary_brand_color) !important;
      color: var(--primary_brand_color) !important;
      font-family: var(--font_family) !important;
      cursor: pointer;
    }
    .link_item {
      font-size: 16px;
      font-family: var(--font_family_Semibold);
      color: var(--text_color);
      width: fit-content;
      cursor: pointer;
    }
    .secondary_header {
      color: var(--primary_brand_color);
      .label{
        font-family: var(--font_family_Semibold);
        font-size: 16px;
        font-weight: var(--font_weight_2);
        color: var(--text_color);
      }
    }
    .secondary_header_text_style {
      color: var(--secondary_header_link_txt_color);
    }
  }
}
