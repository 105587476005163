.compare_plans {
  .label_text {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_1);
    line-height: 2.29;
    color: var(--grey_shade);
  }
  .label_text b {
    color: var(--text_color);
  }
  .section_header {
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    font-weight: var(--font_weight_2);
    line-height: 2.17;
    color: var(--text_color);
  }
  .line_break {
    border-top: 1px solid var(--grey_shade);
  }

  .input_grp_bill {
    display: flex;
    :global(.MuiFilledInput-root) {
      border-radius: 6px 0 0 6px;
      border-right: none !important;
    }
  }
  .input_group_gb {
    height: 55px;
    border-radius: 0 6px 6px 0;
    padding: 0.875rem 0.75rem;
    font-size: 1rem;
    font-weight: var(--font_weight_0);
    white-space: nowrap;
    background-color: var(--bill_cal_input_bg_color);
    border: 1px solid var(--bill_cal_input_border_color);
    color: var(--bill_cal_data_usage_unit_color);
  }
  .bill_cal_popper {
    :global(.MuiPopper-arrow::before) {
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 3.08)) !important;
    }
  }
  .qs_mark_size {
    width: 17px;
    height: 17px;
    margin-bottom: -4px;
    cursor: pointer;
  }
}
