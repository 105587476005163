.terms_page_content {
    .content{
        margin-left: 90px;
        margin-right: 90px;
    }
    @media screen and (max-width: 500px) {
        .content{
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    @media screen and  (min-width: 1600px) {
        .content{
            margin-left: 190px;
            margin-right: 190px;
        }
    }
    .terms_title {
        font-family: var(--font_family_Semibold);
        font-size: 28px;
        font-weight: var(--font_weight_2);
        line-height: 1.43;
        letter-spacing: normal;
        color: var(--text_color);
        text-align: center;
        padding-top: 50px;
    }

    .terms_headings {
        font-family: var(--font_family_Bold);
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        color: var(--black);
    }

    .terms_para {
        font-family: "SharpSans-Book",sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 2.27;
        letter-spacing: normal;
        color: var(--text_color_3);
        text-align: left;
        margin: 20px 0px 20px 0px;
    }
    a {
        text-decoration: none;
        color: var(--dusk);
        font-weight: bold;
    }
    
    p {
        margin-top: 20px;
        font-family: var(--font_family_Medium);
    }
    
    li {
        font-family: var(--font_family_Medium);
        font-size: 15px;
        font-weight: normal;
        line-height: 2.27;
        letter-spacing: normal;
        color: var(--text_color_3);
        text-align: left;
    }
    .caps_para {
        font-family: var(--font_family_Medium);
        font-size: 16px;
        font-weight: var(--font_weight_4);
        line-height: 1.7;
        letter-spacing: normal;
        color: var(--text_color_3);
        text-align: left;
        margin: 20px 0px 20px 0px;
    }
}

.terms_line {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
    margin: 10px auto 50px auto;
}